/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from "react";
import {
  SimpleForm,
  SaveButton,
  SelectInput,
  DateInput,
  Toolbar,
  useRecordContext,
  useRedirect,
  required,
} from "react-admin";
import { EventEmitter } from "../EventEmitter";
import { SubResourceList } from "components/Blueprint/GenericComponents/SubResource";

const TerminationSubList = function TerminationSubList(props) {
  const memberId = useRecordContext(props).id;
  const config = {
    backend_resource: "enrollment_modifications",
    title: " > Terminations",
    linking_field: "member_id",
    attributes: [
      { source: "event_data.last_work_date", label: "Last Work Date" },
      { source: "event_data.signature_date", label: "Signature Date" },
    ],
    components: ["List"],
  };

  const SubList = SubResourceList(config);

  return (
    <SubList
      match={{
        params: {
          topic: "termination",
          event_name: "member_termination",
          json_filter: { event_data: { member_id: memberId } },
        },
      }}
    />
  );
};

const TerminationsTabComponent = function TerminationsTabComponent() {
  const memberId = useRecordContext().id;
  const redirect = useRedirect();
  const reasonChoices = [
    { id: "voluntary", name: "Voluntary" },
    { id: "involuntary", name: "Involuntary" },
    { id: "job-eliminated", name: "Job Elminated" },
    { id: "leave-of-absence", name: "Leave of Absence" },
    { id: "member-deceased", name: "Member Deceased" },
    { id: "military-leave", name: "Military Leave" },
  ];

  const [confirmed, setConfirmed] = useState(false);

  const toolbar = (
    <Toolbar>
      <SaveButton
        type="button"
        disabled={!confirmed}
        mutationOptions={{
          onSuccess: () => redirect("show", "members", memberId),
        }}
      />
    </Toolbar>
  );

  return (
    <div>
      <TerminationSubList />
      <EventEmitter
        topic="termination"
        eventName="member_termination"
        record={{ member_id: memberId }}
      >
        <SimpleForm toolbar={toolbar}>
          <SelectInput
            label="Reason"
            source="reason"
            choices={reasonChoices}
            validate={required()}
          />
          <DateInput
            label="Last Work Date"
            source="last_work_date"
            validate={required()}
          />
          <DateInput
            label="Signature Date"
            source="signature_date"
            validate={required()}
          />
          <div style={{ paddingBottom: "20px" }}>
            <label htmlFor="confirmation_checkbox" style={{ color: "red" }}>
              <input
                type="checkbox"
                id="confirmation_checkbox"
                checked={confirmed}
                onChange={() => setConfirmed(!confirmed)}
              />
              I understand I am terminating a member with the above details and
              this cannot be undone.
            </label>
          </div>
        </SimpleForm>
      </EventEmitter>
    </div>
  );
};

const TerminationsTab = function TerminationsTab() {
  return <TerminationsTabComponent key="TerminationsTabComponent" />;
};

export default TerminationsTab;
