/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { useRecordContext } from "react-admin";

import { SubResourceList } from "../../../../GenericComponents/SubResource/components";
import { QleSelectedActionsDisplay } from "./components/QleSelectedActions";
import { QleDashboardLink } from "./components/QleDashboardLink";

const QleSubList = function QleSubList(props) {
  const memberId = useRecordContext(props).id;
  const config = {
    backend_resource: "qualifying_life_events",
    title: " > Qualifying Life Events",
    linking_field: "member_id",
    attributes: [
      { source: "event_type", required: true, label: "Event Type" },
      { source: "event_date", type: "date", label: "Event Date" },
      { source: "status", label: "Status" },
      {
        custom_component: QleSelectedActionsDisplay(),
      },
      {
        custom_component: QleDashboardLink(),
      },
    ],
    components: ["Show", "List"],
  };

  const SubList = SubResourceList(config);

  return <SubList match={{ params: { id: memberId } }} />;
};

export default QleSubList;
