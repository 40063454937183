import * as Choices from "../../SelectChoices";
import DependentAddresses from "../DependentAddresses";
import DependentDashboardButton from "./DependentDashboardButton";

const id = { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] };
const firstName = {
  source: "first_name",
  validations: { required: true },
  label: "First Name",
  sortable: true,
};
const middleName = {
  source: "middle_name",
  label: "Middle Name",
  sortable: true,
};
const lastName = {
  source: "last_name",
  validations: { required: true },
  label: "Last Name",
  sortable: true,
};
const prefferedName = {
  source: "preferred_name",
  label: "Preferred Name",
  sortable: true,
};
const relationship = {
  source: "relationship",
  validations: { required: true },
  label: "Relationship",
  choices: Choices.RELATIONSHIP,
  type: "drop-down",
};
const sex = {
  source: "sex",
  validations: { required: true },
  label: "Sex",
  choices: Choices.SEX,
  type: "radio",
};
const dateOfBirth = {
  source: "date_of_birth",
  validations: { required: true },
  label: "DOB",
  type: "date",
};
const associatedMemberFirstName = {
  source: "first_name",
  validations: { required: true },
  label: "Member",
  sortable: true,
  reference: [["members", "member_id"]],
};
const listableSSN = {
  source: "ssn",
  label: "SSN",
  exclude: ["Show", "Create", "Edit"],
};
const createableSSN = {
  source: "ssn",
  label: "SSN",
  type: "ssn",
  exclude: ["List"],
};

export default {
  top_level_resource_config: {
    title: "Dependents",
    backend_resource: "member_dependents",
    mapped_to: ["noyo", "justworks"],
    title_identifiers: ["first_name", "last_name"],
    record_representation: ({ first_name, last_name, relationship }) =>
      `${first_name} ${last_name} (${relationship})`,
    attributes: [
      id,
      firstName,
      middleName,
      lastName,
      prefferedName,
      relationship,
      sex,
      dateOfBirth,
      associatedMemberFirstName,
      listableSSN,
      createableSSN,
      {
        custom_component: DependentDashboardButton(),
        exclude: ["Create", "Edit"],
      },
    ],
    components: ["Show", "Create", "Edit"],
    sub_resources: [DependentAddresses.sub_resource_config],
  },
  sub_resource_config: {
    tab_name: "Dependents",
    backend_resource: "member_dependents",
    linking_field: "member_id",
    attributes: [
      firstName,
      middleName,
      lastName,
      prefferedName,
      relationship,
      sex,
      dateOfBirth,
      createableSSN,
      listableSSN,
    ],
    components: ["Show", "Create", "List"],
  },
};
