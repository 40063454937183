import { DataProvider, SsoDataProvider } from "../../../lib/DataProvider";

const { REACT_APP_INTERNAL_TOOLS_ENABLE_SSO } = process.env;

const useSSO = window.localStorage.getItem("use_sso") === "true";
const ssoEnabled = REACT_APP_INTERNAL_TOOLS_ENABLE_SSO === "true" || useSSO; // Default to false if unset

const { getList: getListLegacy, getOne: getOneLegacy } = DataProvider;
const { getList: getListSso, getOne: getOneSso } = SsoDataProvider;

const getList = ssoEnabled ? getListSso : getListLegacy;
const getOne = ssoEnabled ? getOneSso : getOneLegacy;

const FULL_TIME = "full-time";

const PART_TIME = "part-time";

const TERMINATED = "terminated";

const MEMBER_HAS_COVERAGE = {
  "Waive All Coverage": "N",
  "Health Coverage (Y/N)": "Y",
};

const MEMBER_FULL_TIME_BEN_ELIGIBLE = {
  "Product Category": "All Active Full Time",
};

const MEMBER_PART_TIME_BEN_ELIGIBLE = {
  "Product Category": "All Active Part Time",
};

const MEMBER_NO_COVERAGE = {
  "Waive All Coverage": "Y",
  "Health Coverage (Y/N)": "N",
};

// Note: does not include canceled which is omitted from query
const MEMBER_DECLINED_COVERAGE_KEYS = [
  "confirmed_declined",
  "passive_declined",
];

const BSBS_HEADER_MAPPER = {
  street_one: "Address 1",
  street_two: "Address 2",
  middle_name: "Mid Init",
  health_insurance_eligibility_date: "Hire Date",
  ssn: "SSN",
  minutes_worked: "Hrs/Week",
  plan_name: "Selected Plan",
  sex: "Gender",
  relationship: "Relationship Code",
  coverage_tier: "Health Coverage Type",
  company_id: "Sage Company UUID",
};

const BCBS_DEFAULT_VALUES = {
  "Native Language": "UND",
  Written: "UND",
  Spoken: "UND",
  "Race 1": "UNK",
  "Relationship Code": "Employee",
  Ethnicity: "UNK",
  "Ethnicity Code": "UNK",
  "Race Code": "UNK",
};

const KEYS_TO_REMOVE = [
  "id",
  "address_type",
  "country",
  "company_plan_offering_id",
  "member_dependent_ids",
  "plan_year_id",
  "company_benefit_offering_id",
  "plan_id",
  "benefit_type",
  "employment_type",
  "end_date",
  "selection_status",
  "coverage_start_date",
  "preferred_name",
  // We should not be relying on start_date for hire date.
  // Also, this could end up pointing to the wrong model since member addresses
  // and employment states both have start dates and the data is collapsed.
  "start_date",
  // the following columns are, somehow, being passed down to the row data unexpectedly.
  // as a stop-gap, we are manually removing them here when calling filterKeys.
  "ability_groups",
  "company_benefit_offering_ids",
  "medical_waiver_status",
  "trigger_type",
  "trigger_id",
  "locked?",
  "ready_to_enroll",
  "incomplete_enrollment_prerequisites",
];

const convertDateFormat = (date) => {
  if (!date) {
    return "";
  }
  const [year, month, day] = date.split("-");
  return [month, day, year].join("/");
};

const memberBenefitsEligible = (memberEmploymentState) => {
  if (memberEmploymentState === undefined) return {};

  if (memberEmploymentState.employment_status === TERMINATED) return {};
  else if (memberEmploymentState.employment_type === FULL_TIME) {
    return MEMBER_FULL_TIME_BEN_ELIGIBLE;
  } else if (memberEmploymentState.employment_type === PART_TIME) {
    if (memberEmploymentState.minutes_worked >= 1800) {
      return MEMBER_PART_TIME_BEN_ELIGIBLE;
    } else {
      return {};
    }
  }
  return {};
};

const convertMinutesToHours = (minutes) => minutes / 60;

const getMiddleInitial = (middleName) => middleName && middleName[0];

const convertCoverageTier = (coverageTier) => {
  if (coverageTier === "employee") return "EO - Employee Only";
  if (coverageTier === "employee_plus_spouse") return "ES - Employee + Spouse";
  if (coverageTier === "employee_plus_children") return "EC - Employee + Child";
  if (coverageTier === "employee_plus_family") return "EF - Employee + Family";
  return coverageTier;
};

const convertDependentRelationship = (relationship) => {
  relationship = relationship.toLowerCase().replace(/-/g, " ");

  const SPOUSE_RELATIONSHIP_TYPES = [
    "domestic partner",
    "dependent spouse",
    "spouse",
  ];

  const SPOUSE_RELATIONSHIP = "Dependent Spouse";
  const CHILD_RELATIONSHIP = "Dependent Child";
  if (SPOUSE_RELATIONSHIP_TYPES.includes(relationship)) {
    return SPOUSE_RELATIONSHIP;
  }
  return CHILD_RELATIONSHIP;
};

const BCBSTransformationObj = {
  date_of_birth: convertDateFormat,
  health_insurance_eligibility_date: convertDateFormat,
  minutes_worked: convertMinutesToHours,
  middle_name: getMiddleInitial,
  coverage_tier: convertCoverageTier,
  relationship: convertDependentRelationship,
};

async function fetchAll(resource, filter = {}, pagination = {}, sort = {}) {
  return getList(resource, { filter, pagination, sort });
}

function keyTransformations(obj, transformationsObj) {
  return Object.keys(transformationsObj).reduce(
    (prev, curr) => {
      const transformer = transformationsObj[curr];
      if (!Object.prototype.hasOwnProperty.call(prev, curr)) return prev;
      prev[curr] = transformer(prev[curr]);
      return prev;
    },
    { ...obj }
  );
}

const generateDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  return `${mm}/${dd}/${yyyy}`;
};

const humanize = (str) => {
  let i;
  const frags = str.split("_");
  for (i = 0; i < frags.length; i += 1) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

const humanizeObjectKeys = (memberObj) =>
  Object.keys(memberObj).reduce((prev, curr) => {
    const value = memberObj[curr];
    const humanizedKey = humanize(curr);
    prev[humanizedKey] = value;
    return prev;
  }, {});

const normalizeObjectKeys = (memberObj) =>
  Object.keys(BSBS_HEADER_MAPPER).reduce(
    (prev, curr) => {
      const CSKey = curr;
      const BCBSKey = BSBS_HEADER_MAPPER[curr];
      const value = memberObj[curr];
      if (value) {
        prev[BCBSKey] = value;
      }
      delete prev[CSKey];
      return prev;
    },
    { ...memberObj }
  );

const filterKeys = (keysToRemove, memberObj) =>
  keysToRemove.reduce(
    (prev, curr) => {
      delete prev[curr];
      return prev;
    },
    { ...memberObj }
  );

const getCompanyPlanOfferings = async (memberBenefitSelections) =>
  fetchAll("company_plan_offerings", {
    id: memberBenefitSelections.map((plan) => plan.company_plan_offering_id),
  });

const getData = (arr, identifier, value) =>
  arr.filter((elem) => elem[identifier] === value);

const rowAdjustment = (row) => {
  row = filterKeys(KEYS_TO_REMOVE, row);
  row = keyTransformations(row, BCBSTransformationObj);
  row = normalizeObjectKeys(row);
  row = humanizeObjectKeys(row);
  return row;
};

const getcompanyName = async (memberId, memberData) => {
  const member = getData(memberData, "id", memberId)[0];
  const companyId = member.company_id;
  const { data: company } = await getOne("companies", {
    id: companyId,
  });
  return { company_name: company.name };
};

const getSelectedPlan = async (
  memberId,
  memberBenefitSelections,
  companyPlanOfferings
) => {
  // we are currently using the most RECENT member selection
  // that matches the given member_id
  const [memberBenefitSelectionData] = getData(
    memberBenefitSelections,
    "member_id",
    memberId
  );

  if (
    !memberBenefitSelectionData ||
    !memberBenefitSelectionData.company_plan_offering_id ||
    MEMBER_DECLINED_COVERAGE_KEYS.includes(
      memberBenefitSelectionData.selection_status
    )
  ) {
    return { ...MEMBER_NO_COVERAGE };
  }
  // we are currently only processing the first company_plan_offering's details
  // based off of the first memberBenefitSelection's company_plan_offering_id,
  // this is determined by the first memberBenefitSelections record that matches the memberId
  // so, if there are multiple member_benefit_selections,
  // we will only derive the selected plan from the first one returned (most recent).
  const [companyPlanOfferingDetails] = getData(
    companyPlanOfferings,
    "id",
    memberBenefitSelectionData.company_plan_offering_id
  );
  const { data: selectedPlanData } = await getOne("plans", {
    id: companyPlanOfferingDetails.plan_id,
  });
  return {
    "Selected Plan": selectedPlanData.name,
    ...MEMBER_HAS_COVERAGE,
    ...memberBenefitSelectionData,
    "Signature Date": generateDate(),
  };
};

const constructMemberExportRows = async (memberIds) => {
  const { data: memberData } = await fetchAll("members", {
    id: memberIds,
    unmask_secrets: true,
  });
  const { data: memberAddresses } = await fetchAll("member_addresses", {
    member_id: memberIds,
  });
  const { data: memberEmploymentStates } = await fetchAll(
    "member_employment_states",
    { member_id: memberIds }
  );

  // sort by updated_at so we can use the most recent member selection for each member
  const { data: memberBenefitSelections } = await fetchAll(
    "member_benefit_selections",
    {
      member_id: memberIds,
      // omit 'canceled' statuses from this query;
      // if the most recent selection is canceled, we will use the most recent alternate status
      // if a member has no alternate status, we will report them having no coverage by default
      selection_status: [
        "new",
        "confirmed",
        "confirmed_declined",
        "passive_declined",
        "enrolled",
      ],
    },
    {},
    { field: "updated_at", order: "DESC" }
  );

  const { data: memberEligibilityStates } = await fetchAll(
    "member_eligibilities",
    { member_id: memberIds }
  );

  const { data: companyPlanOfferings } = await getCompanyPlanOfferings(
    memberBenefitSelections
  );
  const { data: memberDependents } = await fetchAll("member_dependents", {
    member_id: memberIds,
    unmask_secrets: true,
  });
  const rows = [];
  for (let i = 0; i < memberIds.length; i += 1) {
    const memberId = memberIds[i];
    const selectedPlanDetails = await getSelectedPlan(
      memberId,
      memberBenefitSelections,
      companyPlanOfferings
    );
    const companyName = await getcompanyName(memberId, memberData);

    const dependents = getData(memberDependents, "member_id", memberId).filter(
      ({ id }) =>
        selectedPlanDetails.member_dependent_ids &&
        selectedPlanDetails.member_dependent_ids.includes(id)
    );

    const memberEmploymentState = getData(
      memberEmploymentStates,
      "member_id",
      memberId
    )[0];

    const memberEligibilityState = getData(
      memberEligibilityStates,
      "member_id",
      memberId
    )[0];

    const row = rowAdjustment({
      ...getData(memberData, "id", memberId)[0],
      ...getData(memberAddresses, "member_id", memberId)[0],
      ...selectedPlanDetails,
      ...companyName,
      ...BCBS_DEFAULT_VALUES,
      ...memberEmploymentState,
      ...memberBenefitsEligible(memberEmploymentState),
      ...memberEligibilityState,
    });

    rows.push(row);
    const dependentRows = dependents.map((dependentRow) =>
      rowAdjustment(dependentRow)
    );
    dependentRows.forEach((dependent) => rows.push(dependent));
  }
  return rows;
};

export { constructMemberExportRows };
