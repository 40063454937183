import React from "react";
import { Button, Labeled } from "react-admin";
import { useRecordContext } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const CompanyCarrierMemberIdUploadComponent = function (props) {
  const record = useRecordContext(props);
  debugger;

  const openCompanyDashboard = () => {
    const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/companies/${record.company_id}/carrier-member-id-upload`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <Labeled>
        <Button label="Carrier Member Upload" onClick={openCompanyDashboard} />
      </Labeled>
    </div>
  );
};

const CompanyCarrierMemberIdUploadButton =
  function CompanyCarrierMemberIdUploadButton() {
    return (
      <CompanyCarrierMemberIdUploadComponent key="CompanyCarrierMemberIdUploadComponent" />
    );
  };

export default CompanyCarrierMemberIdUploadButton;
