import React from "react";
import { Button } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const openPlanAndContributionMappingTool = (
  CompanyBenefitOfferingId,
  CompanyId
) => {
  const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/company-benefit-offerings/${CompanyBenefitOfferingId}/${CompanyId}/rollover`;
  window.open(dashboardUrl, "_blank");
};

const MapPreviousPlanOfferingsAndContributionsPrompt = ({ data }) => {
  const {
    company_benefit_offering_id: CompanyBenefitOfferingId,
    company_id: CompanyId,
  } = data;

  return (
    <div>
      <h4>New Admin Automated Rollover Process</h4>
      <p>
        Use the new plan and contribution mapping process:
        <ul>
          <li>
            {" "}
            <Button
              label="Automate Plan Mapping and Contribution Copying in Benefits Ops Dashboard"
              onClick={() =>
                openPlanAndContributionMappingTool(
                  CompanyBenefitOfferingId,
                  CompanyId
                )
              }
            />
          </li>
          <li>
            Return here and advance via
            previous_plan_offerings_and_contributions_mapped!
          </li>
        </ul>
      </p>
    </div>
  );
};

export default MapPreviousPlanOfferingsAndContributionsPrompt;
